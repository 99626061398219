import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Button } from "@material-ui/core";
import swal from "sweetalert";

import Item from "./Item";
import InvoiceDialog from "../../../components/Modal/InvoiceModal";
import { get } from "../../../store/lib/Api";
import * as orderActions from "../../../store/actions/orderActions";
import Loading from "../../../utilities/loading";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import RefundModal from "../../../components/Modal/RefundModal";

const UserOrders = (props) => {
  const { match, history } = props ?? {};
  const params = match?.params ?? {};

  const dispatch = useDispatch();
  const [orderItemId, setOrderItemId] = useState("");
  const [dataHtml, setDataHtml] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);

  const auth = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.order?.userOrders) ?? [];

  useEffect(() => {
    dispatch(orderActions.getUserOrders(params?.id, auth?.token));
  }, [params?.id]);

  const handleGeneratePdf = async (orderItemId) => {
    try {
      setLoading(true);
      const headers = { Authorization: `Bearer ${auth?.token}` };
      const downloadInvoice = await get(
        `/order/downloadInvoice/${orderItemId}`,
        headers
      );

      setDataHtml(downloadInvoice);
      setLoading(false);
    } catch (error) {
      swal({
        title: "Download Invoice Failed",
        text: error?.response?.data?.message,
        icon: "error",
      });
      setLoading(false);
    }
  };

  const onCancel = () => {
    new Promise((resolve, reject) => {
      dispatch(
        orderActions.cancelTheOrder(orderItemId, auth?.token, resolve, reject)
      );
    })
      .then(() => {
        const h = swal({
          title: "Order Cancelled",
          text: "Order has been cancelled",
          icon: "success",
        });
        h.then(() => {
          setShowRefundModal(true);
        });
      })
      .catch((error) => {
        swal({
          title: "Cancel Order Failed",
          text: error?.response?.data?.message,
          icon: "error",
        });
      })
      .finally(() => {
        setShowCancelModal(false);
      });
  };

  const onRefund = (payload) => {
    dispatch(
      orderActions.userOrderRefund({ ...payload, orderItemId }, auth?.token)
    );
    setShowRefundModal(false);
  };

  return (
    <Container className="mt-5">
      <Loading
        width={70}
        height={70}
        loading={loading}
        className="kb-loading"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push("/dashboard/users")}
      >
        Back
      </Button>
      {orders?.map((order, index) => {
        return (
          <React.Fragment key={index}>
            <Item
              item={order}
              setOrderItemId={setOrderItemId}
              setShowCancelModal={setShowCancelModal}
              setShowRefundModal={setShowRefundModal}
              handleGeneratePdf={handleGeneratePdf}
            />
            <div className="dropdown-divider" />
          </React.Fragment>
        );
      })}
      {showCancelModal && (
        <ConfirmationModal
          title="Are you sure you want to cancel this order?"
          onConfirm={onCancel}
          onClose={() => setShowCancelModal("")}
        />
      )}
      {dataHtml && (
        <InvoiceDialog dataHtml={dataHtml} onClose={() => setDataHtml(null)} />
      )}
      {showRefundModal && (
        <RefundModal
          onClose={() => setShowRefundModal(false)}
          onConfirm={onRefund}
        />
      )}
    </Container>
  );
};

export default withRouter(UserOrders);
