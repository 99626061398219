import { convertObjectToParamUrl } from "../../utilities";
import { del, get, HeadersOrigin, patch, post } from "./../lib/Api";

export async function articles(query) {
  const headers = {
    ...HeadersOrigin,
    "x-api-key": process.env.REACT_APP_X_API_KEY,
  };
  const params = convertObjectToParamUrl(query);
  return get(`/article${params}`, headers);
}

export async function addView(id, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };

  return get(`/article/view/${id}`, headers);
}

export async function publishArticle(id, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return get(`/article/publish/${id}`, headers);
}

export async function addArticle(article, token) {
  const headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return post("/article", article, headers);
}

export async function deleteArticle(id, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return del(`/article/${id}`, headers);
}

export async function updateArticle(id, article, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return patch(`/article/${id}`, article, headers);
}

export async function addFlag(id, payload, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return post(`/article/flag/${id}`, payload, headers);
}

export async function trashArticle(id, payload, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return post(`/article/trash/${id}`, payload, headers);
}

export async function deleteComment(id, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return del(`/article/comment/${id}`, headers);
}

export async function getArticle(slug) {
  const headers = { "x-api-key": process.env.REACT_APP_X_API_KEY };
  return get(`/article/byname/${slug}`, headers);
}
