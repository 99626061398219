import { toast } from "react-toastify";
import { push } from "react-router-redux";
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../types/user";
import * as service from "./../services/user";
import * as actions from "../actions/userActions";
import { errorResponse } from "./auth";
import { axios, get, patch, post } from "../lib/Api";
import { convertObjectToParamUrl } from "../../utilities";
import { resetArticle } from "../actions/articleActions";
import { resetCategory } from "../actions/categoryActions";
import { resetCoupon } from "../actions/couponAction";
import { resetOrder } from "../actions/orderActions";
import { resetPayment } from "../actions/paymentActions";
import { resetProduct } from "../actions/productActions";
import { resetSetting } from "../actions/settingActions";
import { resetUser } from "../actions/userActions";
import { resetAuth } from "../actions/authActions";

function* getVendor({ token, query }) {
  try {
    const result = yield call(service.getVendor, token, query);
    if (result.status === 200) {
      yield put(actions.getVendorSuccess(result.data));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}
function* getSingleVendor(action) {
  try {
    const result = yield call(service.getVendorStats, action.id, action.token);

    if (result.status === 200) {
      yield put(actions.getSingleVendorSuccess(result.data));
    } else if (
      result.status === 404 ||
      result.status === 500 ||
      result.status === 400
    ) {
      yield put(actions.listVendorFailed(result.data));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
    yield put(actions.listVendorFailed(error.toString()));
  }
}
function* changeVendorStatus(action) {
  try {
    const result = yield call(service.changeStatus, action.data, action.token);

    if (result.status === 200) {
      yield put(
        actions.updateVendorStatusSuccess(result.data.message, action.data)
      );
      toast.success(result?.data?.message);
    } else if (result.status === 404 || result.status === 500) {
      yield put(actions.listVendorFailed(result.data.message));
      toast.success(result?.data?.message);
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
    yield put(actions.listVendorFailed(error.toString()));
  }
}
function* getUser({ token }) {
  const result = yield call(service.getUser, token);
  if (result.status === 200) {
    yield put(actions.getUsersSuccess(result.data));
  }
}

function* getAllUsers({ token }) {
  try {
    yield put(actions.loadingUser());
    const result = yield call(service.getAllUsers, token);
    yield put(actions.getAllUserSuccess(result.data));
    yield put(actions.loadingUserSuccess());
  } catch (error) {
    yield put(actions.loadingUserSuccess());
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* deleteUser(action) {
  const { _id, token } = action.payload;
  try {
    const result = yield call(service.deleteUser, _id, token);
    if (result.status === 200) {
      yield put(actions.deleteUserSuccess(result.data.message));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* deleteUserPermanently(action) {
  const { _id, token } = action.payload;
  try {
    yield put(actions.loadingUser());
    const result = yield call(service.deleteUserPermanently, _id, token);
    yield put(actions.loadingUserSuccess());
    if (result.status === 200) {
      yield put(actions.deleteUserPermanentlySuccess(result.data.message));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* deleteVendor(action) {
  const { _id, token } = action.payload;
  try {
    const result = yield call(service.deleteUserPermanently, _id, token);
    if (result.status === 200) {
      yield put(actions.deleteVendorSuccess(result.data.data));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}
function* getRoles(action) {
  const { token } = action;
  try {
    if (result.status === 200) yield put(actions.getRolesSuccess(result.data));
    const result = yield call(service.getRoles, token);
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}
function* activateVendor(action) {
  try {
    const { token, id } = action.payload;
    const result = yield call(service.activateVendor, id, token);
    if (result.status === 200) {
      yield put(actions.activateUserSuccess(result.data.data));
    } else if (result.status === 404 || result.status === 500) {
      yield put(actions.listVendorFailed(result.data.message));
    }
  } catch (error) {
    yield put(actions.listVendorFailed(error.toString()));
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* updateUserRole({ userId, role, token }) {
  try {
    yield put(actions.loadingUser());
    const result = yield call(service.updateUserRole, userId, role, token);
    yield put(actions.loadingUserSuccess());
    yield put(actions.updateUserRoleSuccess(result.data?.data));
  } catch (error) {
    yield put(actions.loadingUserSuccess());
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* updateUserPassword({ userId, password, token }) {
  try {
    yield put(actions.loadingUser());
    const result = yield call(
      service.updateUserPassword,
      userId,
      password,
      token
    );
    yield put(actions.loadingUserSuccess());
    toast.success(result.data.message);
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
    yield put(actions.loadingUserSuccess());
  }
}

function* getMedia(action) {
  const { token, query } = action;
  try {
    const result = yield call(service.getMedia, token, query);
    if (result.status === 200)
      yield put(actions.getMediaSuccess(result.data.data));
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* deleteMedia({ id, token }) {
  try {
    const result = yield call(service.deleteMedia, id, token);
    yield put(actions.deleteMediaSuccess(result?.data));
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* addUser({ token, payload }) {
  try {
    const result = yield call(service.addUser, payload, token);
    yield put(
      actions.addUserSuccess({
        ...result?.data?.data,
        role: { name: payload?.role?.label },
        password: null,
      })
    );
    toast.success(result.data.message);
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* updateUser({ token, id, payload }) {
  try {
    const result = yield call(service.updateUser, id, payload, token);
    yield put(
      actions.updateUserSuccess({
        ...result?.data?.data,
        role: payload?.roleName,
        password: null,
      })
    );
    toast.success(result.data.message);
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* addFlagToMedia({ id, payload, token }) {
  try {
    const result = yield call(service.addFlagToMedia, id, payload, token);
    yield put(actions.addFlagToMediaSuccess(result?.data?.data));
    toast.success(result?.data?.message);
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* trashMedia({ id, payload, token }) {
  try {
    const result = yield call(service.trashMedia, id, payload, token);
    yield put(actions.trashMediaSuccess(result?.data?.data));
    if (result?.data?.success) {
      toast.error(result?.data?.message);
    } else {
      toast.success(result?.data?.message);
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* trashStore({ id, payload, token }) {
  try {
    const result = yield call(service.trashStore, id, payload, token);
    yield put(actions.trashStoreSuccess(result?.data?.data));
    if (result?.data?.success) {
      toast.error(result?.data?.message);
    } else {
      toast.success(result?.data?.message);
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* getNotifications({ token }) {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const result = yield call(() => get(`/notifications`, headers));
    yield put(actions.getNotificationSuccess(result.data));
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* unreadNotification({ token }) {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const result = yield call(() => get(`/notifications/unread`, headers));
    yield put(actions.unreadNotificationSuccess(result.data?.count ?? 0));
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(resetAuth());
      yield put(resetArticle());
      yield put(resetCategory());
      yield put(resetCoupon());
      yield put(resetOrder());
      yield put(resetPayment());
      yield put(resetProduct());
      yield put(resetSetting());
      yield put(resetUser());
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* markNotification({ token, id }) {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const result = yield call(() =>
      patch(`/notifications/mark/${id}`, {}, headers)
    );
    yield put(actions.markNotificationSuccess(result.data));
  } catch (error) {
    errorResponse(error);
  }
}

function* markAllNotification({ token }) {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    yield call(() => post(`/notifications/mark/all`, {}, headers));
    yield put(actions.markAllNotificationSuccess());
  } catch (error) {
    errorResponse(error);
  }
}

function* getCountries() {
  try {
    const result = yield call(() =>
      axios.get("https://countriesnow.space/api/v0.1/countries/flag/unicode")
    );
    yield put(actions.getCountriesSuccess(result?.data?.data ?? []));
    yield put(actions.getCitiesSuccess([]));
  } catch (error) {
    yield put(actions.getCountriesSuccess([]));
  }
}

function* getStates({ country }) {
  try {
    const result = yield call(() =>
      axios.post("https://countriesnow.space/api/v0.1/countries/states", {
        country,
      })
    );
    yield put(actions.getStatesSuccess(result?.data?.data?.states ?? []));
  } catch (error) {
    yield put(actions.getStatesSuccess([]));
  }
}

function* getCities({ country, state }) {
  try {
    const result = yield call(() =>
      axios.post("https://countriesnow.space/api/v0.1/countries/state/cities", {
        country,
        state,
      })
    );
    yield put(actions.getCitiesSuccess(result?.data?.data ?? []));
  } catch (error) {
    yield put(actions.getCitiesSuccess([]));
  }
}

function* addVendor({ payload, token }) {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const result = yield call(() => post(`/users/vendor`, payload, headers));
    yield put(actions.addVendorSuccess(result?.data));
    toast.success(result?.message);
  } catch (error) {
    errorResponse(error);
  }
}

function* updateVendor({ id, payload, token }) {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const result = yield call(() =>
      patch(`/users/vendor/${id}`, payload, headers)
    );
    yield put(actions.updateVendorSuccess(result?.data));
    toast.success(result?.message);
  } catch (error) {
    errorResponse(error);
  }
}

function* getReviews({ token, query }) {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const result = yield call(() =>
      get(`/users/review/${convertObjectToParamUrl(query)}`, headers)
    );
    yield put(actions.getReviewsSuccess(result?.data));
  } catch (error) {
    errorResponse(error);
  }
}

function* updateReview({ payload, token }) {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const result = yield call(() => post("/users/review", payload, headers));
    toast.success(result?.message);
  } catch (error) {
    yield errorResponse(error);
  }
}

export default function* userWatcher() {
  yield takeLatest(types.GET_VENDORS, getVendor);
  yield takeLatest(types.GET_SINGLE_VENDOR, getSingleVendor);
  yield takeLatest(types.GET_USERS, getUser);
  yield takeLatest(types.GET_ALL_USERS, getAllUsers);
  yield takeLatest(types.DELETE_USER, deleteUser);
  yield takeLatest(types.DELETE_USER_PERMANENTLY, deleteUserPermanently);
  yield takeLatest(types.DELETE_VENDOR, deleteVendor);
  yield takeLatest(types.GET_ROLES, getRoles);
  yield takeLatest(types.ACTIVATE_VENDOR, activateVendor);
  yield takeLatest(types.GET_MEDIA, getMedia);
  yield takeLatest(types.UPDATE_USER, updateUserRole);
  yield takeLatest(types.UPDATE_USER_PASSWORD, updateUserPassword);
  yield takeLatest(types.DELETE_MEDIA, deleteMedia);
  yield takeLatest(types.UPDATE_VENDOR_STATUS, changeVendorStatus);
  yield takeLatest(types.ADD_USER, addUser);
  yield takeLatest(types.UPDATE_USER_BY_ADMIN, updateUser);
  yield takeLatest(types.ADD_FLAG_TO_MEDIA, addFlagToMedia);
  yield takeLatest(types.TRASH_MEDIA, trashMedia);
  yield takeLatest(types.TRASH_STORE, trashStore);
  yield takeLatest(types.GET_NOTIFICATIONS, getNotifications);
  yield takeLatest(types.UNREAD_NOTIFICATION, unreadNotification);
  yield takeLatest(types.MARK_NOTIFICATION, markNotification);
  yield takeLatest(types.MARK_ALL_NOTIFICATIONS, markAllNotification);
  yield takeLatest(types.GET_COUNTRIES, getCountries);
  yield takeLatest(types.GET_STATES, getStates);
  yield takeLatest(types.GET_CITIES, getCities);
  yield takeLatest(types.ADD_VENDOR, addVendor);
  yield takeLatest(types.UPDATE_VENDOR, updateVendor);
  yield takeLatest(types.GET_REVIEWS, getReviews);
  yield takeLatest(types.UPDATE_REVIEW, updateReview);
}
