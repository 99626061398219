export const NotificationType = {
  NEW_VENDOR: "NEW_VENDOR",
  NEW_PRODUCT: "NEW_PRODUCT",
}

export const getNotificationMessageAndLink = (notification) => {
  const { type, vendor, product } = notification;

  switch(type) {
    case NotificationType.NEW_VENDOR: {
      return {
        ...notification,
        message: `${vendor?.firstName} ${vendor?.lastName} recently joined as vendor`,
        link: `/dashboard/vendorstore`
      }
    }
    case NotificationType.NEW_PRODUCT: {
      return {
        ...notification,
        message: `${vendor?.firstName} ${vendor?.lastName} has added new product: <br> ${product?.name}`,
        link: `/dashboard/products`
      }
    }
    default: return;
  }
} 