import React from "react";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const Item = ({ comment, onDelete }) => {
  const user = comment?.user;
  return (
    <div className="item-wrapper d-flex flex-column mx-3 pb-2">
      <div className="d-flex justify-content-between align-items-center">
        <div className="details-wrapper d-flex align-items-center">
          <img
            className="profile"
            src={user?.profilePic}
            width={40}
            height={40}
            alt="Comment profile"
          />
          <div className="name">
            {user?.firstName} {user?.lastName}
          </div>
          <div className="date d-flex align-items-center">
            <div className="dot" />
            {moment(comment?.createdAt).fromNow()}
          </div>
        </div>
        <IconButton onClick={onDelete} size="small" color="secondary">
          <Delete />
        </IconButton>
      </div>
      <div className="message">{comment?.message}</div>
    </div>
  );
};

export default Item;
