import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "react-router-redux";

import * as types from "./../types/coupon";
import * as service from "./../services/coupon";
import * as actions from "./../actions/couponAction";
import { errorResponse, successResponse } from "./auth";

function* getCoupons() {
  try {
    const result = yield call(service.coupons);
    yield put(actions.getCouponsSuccess(result.data));
  } catch (error) {
    errorResponse(error);
    yield put(actions.couponFailed());
  }
}

function* addCoupon(action) {
  try {
    const { coupon, token } = action.payload;
    const result = yield call(service.addCoupon, coupon, token);
    yield put(actions.addCouponSuccess(result.data));
    yield put(push("/dashboard/coupons"));
  } catch (error) {
    errorResponse(error);
    yield put(actions.couponFailed());
  }
}

function* deleteCoupons(action) {
  try {
    const { id, token } = action.payload;
    const result = yield call(service.deleteCoupons, id, token);
    yield put(actions.deleteCouponsSuccess(id));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
    yield put(actions.couponFailed());
  }
}

function* updateCoupon(action) {
  try {
    const { id, coupon, token } = action.payload;
    const result = yield call(service.updateCoupon, id, coupon, token);
    yield put(actions.updateCouponSuccess(result.data));
    yield put(push("/dashboard/coupons"));
  } catch (error) {
    errorResponse(error);
    yield put(actions.couponFailed());
  }
}

export default function* couponWatcher() {
  yield takeLatest(types.GET_COUPONS, getCoupons);
  yield takeLatest(types.DELETE_COUPON, deleteCoupons);
  yield takeLatest(types.ADD_COUPON, addCoupon);
  yield takeLatest(types.UPDATE_COUPON, updateCoupon);
}
