import * as types from "../types/article";
const initialState = {
  articles: [],
  loading: false,
  article: null,
  error: "",
};

const articleReducers = (state = initialState, actions) => {
  switch (actions.type) {
    // Failed List Articles
    case types.LIST_ARTICLE_FAILED: {
      return {
        ...state,
        error: actions.message,
        loading: false,
      };
    }
    case types.ADD_UPDATE_ARTICLE_FAILED: {
      return {
        ...state,
        error: actions.message,
        loading: false,
      };
    }
    case types.GET_ARTICLES:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: actions.articles,
        loading: false,
      };
    //Delete Article
    case types.DELETE_ARTICLE:
    case types.TRASH_ARTICLE:
      return { ...state, loading: true };
    case types.DELETE_ARTICLE_SUCCESS:
    case types.TRASH_ARTICLE_SUCCESS: {
      const payload = actions.payload;
      const articles = state?.articles ?? [];
      const filteredArticle = articles.filter((e) => e._id !== payload?._id);
      return (state = {
        ...state,
        articles: filteredArticle,
        loading: false,
      });
    }

    case types.PUBLISH_ARTICLE:
      return { ...state, loading: true };
    case types.PUBLISH_ARTICLE_SUCCESS: {
      const articles = state.articles.map((ele) => {
        if (ele._id === actions.data._id) {
          return { ...ele, isPublished: actions.data.isPublished };
        } else {
          return ele;
        }
      });
      return { ...state, articles, loading: false };
    }
    //Add View
    case types.ADD_ARTICLE: {
      return { ...state, loading: true };
    }
    case types.ADD_ARTICLE_VIEW_SUCCESS: {
      const articles = [...state.articles];
      const index = articles.findIndex((e) => e._id === actions.data._id);
      articles[index].views = actions.data.views;
      return { ...state, articles, loading: false };
    }
    case types.UPDATE_ARTICLE:
      return {
        ...state,
        loading: true,
      };

    //ADD ARTICLE
    case types.ADD_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    //Update
    case types.UPDATE_ARTICLE_SUCCESS: {
      let articles = [...state.articles];
      let index = articles.findIndex((e) => e._id === actions.data._id);
      articles[index].articlePic = actions.data.article.articlePic;
      articles[index].name = actions.data.article.name;
      articles[index].content = actions.data.article.content;
      return { ...state, articles, loading: false };
    }
    case types.ADD_FLAG_SUCCESS: {
      const payload = actions.payload;
      const filteredArticles = state.articles.map((ele) => {
        if (ele?._id == payload?._id) {
          return { ...ele, ...payload };
        } else {
          return ele;
        }
      });
      return {
        ...state,
        articles: filteredArticles,
      };
    }
    case types.GET_ARTICLE_SUCCESS: {
      return {
        ...state,
        article: actions?.payload,
      };
    }
    case types.DELETE_COMMENT_SUCCESS: {
      const filteredComment = state.article.comments?.filter(
        (comment) => comment._id != actions.payload
      );
      return {
        ...state,
        article: { ...state.article, comments: filteredComment },
      };
    }
    case types.ARTICLE_FAILED: {
      return { ...state, loading: false };
    }
    case types.RESET_ARTICLE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default articleReducers;
