/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
import { connect } from "react-redux";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import AdminFooter from "../components/Footers/AdminFooter.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";

import * as categoryActions from "../store/actions/categoryActions";
import * as userActions from "../store/actions/userActions.js";

import routes from "../routes.js";
import CreateProduct from "../views/Product/CreateProduct.jsx";
import CreateCoupon from "../views/Coupon/CreateCoupon.js";
import CreateCategory from "../views/Category/CreateCategory";
import StoreSetting from "../views/Setting/StoreSetting";
import CustomerSupport from "../views/Setting/CustomerSupport";
import CommissionSetting from "../views/Setting/CommissionSetting";
import PoliciesSetting from "../views/Setting/Policies";
import PaymentSetting from "../views/Setting/PaymentSetting";
import CreateArticle from "../views/Article/CreateArticle.js";
import CreateVendor from "../views/StoreVendor/CreateVendor";
import OrderActivity from "../views/Order/Activity";
import StoreVendor from "../views/StoreVendor/StoreVender.js";
import Customers from "../views/User/Users";

import ProtectedRoute from "../protected.route";
import ProductSetting from "../views/Setting/product";
import Notifications from "../components/Notifications";
import UserOrders from "../views/Order/UserOrders";
import UsersWithRoles from "../views/User/UsersWithRoles.jsx";
import Media from "../views/User/Media.jsx";
import Articles from "../views/Article/Articles.js";
import Products from "../views/Product/Products.jsx";
import Categories from "../views/Category/Categories.jsx";
import Orders from "../views/Order/Orders.js";
import Withdrawl from "../views/Withdrawal/Withdrawl.js";
import Coupons from "../views/Coupon/Coupons.js";
import SingleStoreVendor from "../views/StoreVendor/SingleStoreVendor.js";
import Refunds from "../views/Refund/Refunds.js";
import Trash from "../views/Trash/index.jsx";
import Feedback from "../views/Feedback/index.jsx";
import Settings from "../views/Setting/Settings.js";
import Reports from "../views/Report/Reports.js";
import Reviews from "../views/Reviews/Reviews.js";
import Index from "../views/Index.jsx";

class Dashboard extends React.Component {
  componentDidMount() {
    this.props.unreadNotification(this.props?.auth?.token);
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/auth/login");
      this.props.setRedirectTo(this.props.location.pathname);
    }
  }
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    let adminRoutes = [];
    // routes.splice((routes.length - 2), 1);
    return routes.map((prop, key) => {
      if (prop.layout === "/dashboard") {
        adminRoutes.push(
          <ProtectedRoute
            path={prop.path}
            component={prop.component}
            // key={key}
          />
        );
      } else {
        return null;
      }
      return adminRoutes;
    });
  };
  getBrandText = (path) => {
    // for (let i = 0; i < routes.length; i++) {
    //   if (
    //     this.props.location.pathname.indexOf(
    //       routes[i].layout + routes[i].path
    //     ) !== -1
    //   ) {
    //     return routes[i].name;
    //   }
    // }
    return "Brand";
  };
  render() {
    const { auth } = this.props;
    return (
      <>
        <Sidebar
          {...this.props}
          auth={this.props?.auth}
          routes={
            auth?.role == "admin"
              ? routes
              : routes.filter(
                  (item) =>
                    auth?.accesses?.includes(item?.access) || item?.component
                )
          }
          logo={{
            innerLink: "/dashboard/home",
            imgSrc: require("../assets/img/brand/argon-react.png"),
            imgAlt: "...",
          }}
          notificationCount={this.props.notificationCount}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            auth={this.props?.auth}
            // brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            <ProtectedRoute path="/dashboard/home" component={Index} />
            <ProtectedRoute
              path="/dashboard/orders/activity/:id"
              component={OrderActivity}
            />
            <ProtectedRoute
              path="/dashboard/users-with-roles"
              component={UsersWithRoles}
            />
            <ProtectedRoute path="/dashboard/medias" component={Media} />
            <ProtectedRoute path="/dashboard/articles" component={Articles} />
            <ProtectedRoute path="/dashboard/products" component={Products} />
            <ProtectedRoute
              path="/dashboard/categories"
              component={Categories}
            />
            <ProtectedRoute path="/dashboard/orders" component={Orders} />
            <ProtectedRoute path="/dashboard/withdraw" component={Withdrawl} />
            <ProtectedRoute path="/dashboard/coupons" component={Coupons} />
            <ProtectedRoute
              path="/dashboard/vendorstore"
              component={StoreVendor}
            />
            <ProtectedRoute
              path="/dashboard/vendor-store/:id"
              component={SingleStoreVendor}
            />
            <ProtectedRoute path="/dashboard/users" component={Customers} />
            <ProtectedRoute path="/dashboard/user/:id" component={UserOrders} />
            <ProtectedRoute path="/dashboard/refund" component={Refunds} />
            <ProtectedRoute path="/dashboard/trash" component={Trash} />
            <ProtectedRoute path="/dashboard/feedback" component={Feedback} />
            <ProtectedRoute path="/dashboard/setting" component={Settings} />
            <ProtectedRoute path="/dashboard/reports" component={Reports} />
            <ProtectedRoute path="/dashboard/review" component={Reviews} />
            <ProtectedRoute
              path="/dashboard/add-product"
              component={CreateProduct}
            />
            <ProtectedRoute
              path="/dashboard/add-vendor"
              component={CreateVendor}
            />
            <ProtectedRoute
              path="/dashboard/add-article"
              component={CreateArticle}
            />
            <ProtectedRoute
              path="/dashboard/createcoupons"
              component={CreateCoupon}
            />
            <ProtectedRoute
              path="/dashboard/add-category"
              component={CreateCategory}
            />
            <ProtectedRoute
              path="/dashboard/notifications"
              component={Notifications}
            />
            <ProtectedRoute
              path="/dashboard/StoreSetting"
              component={StoreSetting}
            />
            <ProtectedRoute
              path="/dashboard/productsetting"
              component={ProductSetting}
            />
            <ProtectedRoute
              path="/dashboard/customersupport"
              component={CustomerSupport}
            />
            <ProtectedRoute
              path="/dashboard/commissionsetting"
              component={CommissionSetting}
            />
            <ProtectedRoute
              path="/dashboard/storepolicies"
              component={PoliciesSetting}
            />
            <ProtectedRoute
              path="/dashboard/PaymentSetting"
              component={PaymentSetting}
            />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    category: state.categories,
    notificationCount: state.user.notificationCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRedirectTo: (payload) => dispatch(userActions.setRedirectTo(payload)),
    unreadNotification: (token) =>
      dispatch(userActions.unreadNotification(token)),
    getCategories: () => dispatch(categoryActions.getCategories()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
