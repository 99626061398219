import * as types from "./../types/setting";
import * as settingService from "../services/setting";
import { errorResponse, successResponse } from "../sagas/auth";

export const getCommisionSettings = () => {
  return {
    type: types.GET_COMMISSION_SETTING,
  };
};

export const getCommisionSettingsSuccess = (settings) => {
  return {
    type: types.GET_COMMISSION_SETTING_SUCCESS,
    settings,
  };
};

export const addCommisionSettings = (settings, token) => {
  return {
    type: types.ADD_COMMISSION_SETTING,
    payload: { settings, token },
  };
};

export const addCommisionSettingsSuccess = (data) => {
  return {
    type: types.ADD_COMMISSION_SETTING_SUCCESS,
    data,
  };
};

export const getAllDeliveryOptionsWithSection = (options) => {
  return async (dispatch) => {
    try {
      if (options) {
        dispatch({
          type: types.GET_ALL_DELIVERY_OPTIONS_SUCCESS,
          payload: options,
        });
      } else {
        dispatch({ type: types.GET_ALL_DELIVERY_OPTIONS });
        const result = await settingService.getAllDeliveryOptionsWithSection();
        dispatch({
          type: types.GET_ALL_DELIVERY_OPTIONS_SUCCESS,
          payload: result.data,
        });
      }
    } catch (error) {
      errorResponse(error);
      dispatch({ type: types.SETTING_FAILED });
    }
  };
};

export const addOption = (option, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.ADD_OPTION });
      const result = await settingService.addOption(option, token);
      dispatch({ type: types.ADD_OPTION_SUCCESS, payload: result.data });
      successResponse(result);
    } catch (error) {
      errorResponse(error);
      dispatch({ type: types.SETTING_FAILED });
    }
  };
};

export const editOption = (option, optionId, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.EDIT_OPTION });
      const result = await settingService.editOption(option, optionId, token);
      dispatch({ type: types.EDIT_OPTION_SUCCESS, payload: result.data });
      successResponse(result);
    } catch (error) {
      errorResponse(error);
      dispatch({ type: types.SETTING_FAILED });
    }
  };
};

export const deleteOption = (optionId, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_OPTION });
      const result = await settingService.deleteOption(optionId, token);
      dispatch({ type: types.DELETE_OPTION_SUCCESS, payload: result.data });
    } catch (error) {
      errorResponse(error);
      dispatch({ type: types.SETTING_FAILED });
    }
  };
};

export const addNewSection = (section, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.ADD_SECTION });
      const result = await settingService.addSection(section, token);
      dispatch({ type: types.ADD_SECTION_SUCCESS, payload: result.data });
    } catch (error) {
      errorResponse(error);
      dispatch({ type: types.SETTING_FAILED });
    }
  };
};

export const deleteSection = (sectionId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_SECTION });
      const response = await settingService.deleteSection(sectionId);
      if (response.data.result === "success") {
        dispatch({
          type: types.DELETE_SECTION_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: types.DELETE_SECTION_FAILURE,
          payload: response.data.result,
        });
      }
    } catch (err) {
      dispatch({ type: types.DELETE_SECTION_FAILURE, payload: err.message });
    }
  };
};

export const updatePriority = (options, token) => ({
  type: types.UPDATE_PRIORITY,
  payload: { options, token },
});

export const updatePriorityForOption = (type, options, token) => ({
  type: types.UPDATE_PRIORITY_FOR_OPTION,
  payload: { type, options, token },
});

export const resetSetting = () => ({
  type: types.RESET_SETTING,
});

export const settingFailed = () => ({
  type: types.SETTING_FAILED,
});
