import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";

import DefaultHeader from "../Headers/DefaultHeader";
import NotificationItem from "./NotificationItem";
import { AntSwitch } from "../UI/Switch";
import * as userActions from "../../store/actions/userActions";
import { authSelector } from "../../store/reducers";

export const Index = (props) => {
  const {
    auth,
    getNotifications,
    notifications,
    unreadNotification,
    markNotification,
    markAllNotifications,
  } = props;
  const [socket, setSocket] = useState(null);
  const [onlyUnread, setOnlyUnread] = useState(false);

  // useEffect(() => {
    // setSocket(io(process.env.REACT_APP_API_ENDPOINT));
  // }, []);

  useEffect(() => {
    if (auth?.token) {
      unreadNotification(auth?.token);
      getNotifications(auth?.token);
    }
  }, [auth?.token]);

  const notificationList = useMemo(() => {
    if(onlyUnread) {
      return notifications?.filter((notification) => notification.status == "UNREAD");
    } else {
      return notifications;
    }
  }, [notifications, onlyUnread]);

  useEffect(() => {
    if (socket && auth.user) {
      socket.emit("newUser", auth.user);
    }
  }, [socket, auth.user]);

  useEffect(() => {
    const handleNotification = () => {
      if (auth?.token) {
        getNotifications(auth?.token);
        unreadNotification(auth?.token);
      }
    };

    if (socket) {
      socket.on("notification", handleNotification);
    }

    return () => {
      if (socket) {
        socket.off("notification", handleNotification);
      }
    };
  }, [socket, auth?.token]);

  return (
    <div>
      <DefaultHeader headerPadding={true} />
      <div className="notification-container">
        <div className="section-title">Notifications</div>
        <div className="header">
          <div className="switch-wrapper">
            <AntSwitch
              checked={onlyUnread}
              onChange={(e) => setOnlyUnread(e.target.checked)}
              inputProps={{ "aria-label": "ant design" }}
            />
            <div className="text-sm cursor-pointer">Only an unread</div>
          </div>
          <div
            onClick={() => markAllNotifications(auth?.token)}
            className="custom-link text-sm"
          >
            Mark all as read
          </div>
        </div>
      </div>
      <h2 className="border-t-2 border-gray-200 mb-3" />
      <div className="notification-list">
        {notificationList?.map((notification, index) => (
          <NotificationItem
            key={index}
            token={auth?.token}
            notification={notification}
            markNotification={markNotification}
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: authSelector(state),
    notifications: state.user.notifications,
    notificationCount: state.user.notificationCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (token) => dispatch(userActions.getNotifications(token)),
    unreadNotification: (token) =>
      dispatch(userActions.unreadNotification(token)),
    markNotification: (token, id) =>
      dispatch(userActions.markNotification(token, id)),
    markAllNotifications: (token) =>
      dispatch(userActions.markAllNotifications(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
