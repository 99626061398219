import * as types from "../types/user";

export const loadingUser = () => ({
  type: types.LOADING,
});

export const loadingUserSuccess = () => ({
  type: types.LOADING_SUCCESS,
});

export const getVendor = (token, query) => {
  return {
    type: types.GET_VENDORS,
    token,
    query,
  };
};
export const getVendorSuccess = (data) => {
  return {
    type: types.GET_VENDORS_SUCCESS,
    vendors: data,
  };
};

export const activeVendor = (id, token) => {
  return {
    type: types.ACTIVATE_VENDOR,
    payload: { id, token },
  };
};

export const getCurrentVendor = (id, token) => {
  return {
    type: types.GET_SINGLE_VENDOR,
    id,
    token,
  };
};

export const getSingleVendorSuccess = (data) => {
  return {
    type: types.GET_SINGLE_VENDOR_SUCCESS,
    vendor: data,
  };
};

export const activeVendorSuccess = (data) => {
  return {
    type: types.ACTIVATE_VENDOR_SUCCESS,
    data,
  };
};

export const listVendorFailed = (message) => {
  return {
    type: types.LIST_USER_FAILED,
    message,
  };
};

export const addUpdateVendorFailed = (message) => {
  return {
    type: types.ADD_UPDATE_USER_FAILED,
    message,
  };
};

export const activateUserSuccess = (data) => {
  return {
    type: types.ACTIVATE_USER_SUCCESS,
    data,
  };
};

export const updateUserRole = (userId, role, token) => ({
  type: types.UPDATE_USER,
  userId,
  role,
  token,
});

export const updateUserPassword = (userId, password, token) => ({
  type: types.UPDATE_USER_PASSWORD,
  userId,
  password,
  token,
});

export const updateUserRoleSuccess = (payload) => ({
  type: types.UPDATE_USER_SUCCESS,
  payload,
});

export const getUsers = (token) => {
  return {
    type: types.GET_USERS,
    token,
  };
};
export const getUsersSuccess = (data) => {
  return {
    type: types.GET_USERS_SUCCESS,
    user: data,
  };
};

export const getAllUsers = (token) => ({
  type: types.GET_ALL_USERS,
  token,
});

export const getAllUserSuccess = (payload) => ({
  type: types.GET_ALL_USERS_SUCCESS,
  payload,
});

export const deleteUser = (_id, token) => {
  return {
    type: types.DELETE_USER,
    payload: { _id, token },
  };
};
export const deleteUserSuccess = (updateUser) => {
  return {
    type: types.DELETE_USER_SUCCESS,
    updateUser,
  };
};
export const deleteUserPermanently = (_id, token) => {
  return {
    type: types.DELETE_USER_PERMANENTLY,
    payload: { _id, token },
  };
};
export const deleteUserPermanentlySuccess = (payload) => {
  return {
    type: types.DELETE_USER_PERMANENTLY_SUCCESS,
    payload,
  };
};
export const deleteVendor = (_id, token) => {
  return {
    type: types.DELETE_VENDOR,
    payload: { _id, token },
  };
};
export const deleteVendorSuccess = (updateUser) => {
  return {
    type: types.DELETE_VENDOR_SUCCESS,
    payload: updateUser,
  };
};
export const getRoles = (token) => {
  return {
    type: types.GET_ROLES,
    token,
  };
};
export const getRolesSuccess = (roles) => {
  return {
    type: types.GET_ROLES_SUCCESS,
    roles,
  };
};
export const getMedia = (token, query) => {
  return {
    type: types.GET_MEDIA,
    token,
    query,
  };
};
export const getMediaSuccess = (medias) => {
  return {
    type: types.GET_MEDIA_SUCCESS,
    medias,
  };
};

export const deleteMedia = (id, token) => {
  return {
    type: types.DELETE_MEDIA,
    id,
    token,
  };
};

export const deleteMediaSuccess = (payload) => {
  return {
    type: types.DELETE_MEDIA_SUCCESS,
    payload,
  };
};

export const updateVendorStatus = (data, token) => {
  return {
    type: types.UPDATE_VENDOR_STATUS,
    data,
    token,
  };
};

export const updateVendorStatusSuccess = (message, data) => {
  return {
    type: types.UPDATE_VENDOR_STATUS_SUCCESS,
    message,
    data,
  };
};
export const removeSuccessMessage = () => {
  return {
    type: types.REMOVE_SUCCESS_MSG,
  };
};

export const addUser = (token, payload) => ({
  type: types.ADD_USER,
  token,
  payload,
});

export const addUserSuccess = (payload) => ({
  type: types.ADD_USER_SUCCESS,
  payload,
});

export const updateUser = (token, id, payload) => ({
  type: types.UPDATE_USER_BY_ADMIN,
  token,
  id,
  payload,
});

export const updateUserSuccess = (payload) => ({
  type: types.UPDATE_USER_BY_ADMIN_SUCCESS,
  payload,
});

export const addFlagToMedia = (id, payload, token) => ({
  type: types.ADD_FLAG_TO_MEDIA,
  id,
  payload,
  token,
});

export const addFlagToMediaSuccess = (payload) => ({
  type: types.ADD_FLAG_TO_MEDIA_SUCCESS,
  payload,
});

export const trashMedia = (id, payload, token) => {
  return {
    type: types.TRASH_MEDIA,
    id,
    payload,
    token,
  };
};

export const trashMediaSuccess = (payload) => {
  return {
    type: types.TRASH_MEDIA_SUCCESS,
    payload,
  };
};

export const trashStore = (id, payload, token) => {
  return {
    type: types.TRASH_STORE,
    id,
    payload,
    token,
  };
};

export const trashStoreSuccess = (payload) => {
  return {
    type: types.TRASH_STORE_SUCCESS,
    payload,
  };
};

export const getNotifications = (token) => ({
  type: types.GET_NOTIFICATIONS,
  token,
});

export const getNotificationSuccess = (payload) => ({
  type: types.GET_NOTIFICATIONS_SUCCESS,
  payload,
});

export const unreadNotification = (token) => ({
  type: types.UNREAD_NOTIFICATION,
  token,
});

export const unreadNotificationSuccess = (payload) => ({
  type: types.UNREAD_NOTIFICATION_SUCCESS,
  payload,
});

export const markNotification = (token, id) => ({
  type: types.MARK_NOTIFICATION,
  token,
  id,
});

export const markNotificationSuccess = (payload) => ({
  type: types.MARK_NOTIFICATION_SUCCESS,
  payload,
});

export const markAllNotifications = (token) => ({
  type: types.MARK_ALL_NOTIFICATIONS,
  token,
});

export const markAllNotificationSuccess = () => ({
  type: types.MARK_ALL_NOTIFICATIONS_SUCCESS,
});

export const getCountries = () => ({
  type: types.GET_COUNTRIES,
});

export const getCountriesSuccess = (payload) => ({
  type: types.GET_COUNTRIES_SUCCESS,
  payload,
});

export const getStates = (country) => ({
  type: types.GET_STATES,
  country,
});

export const getStatesSuccess = (payload) => ({
  type: types.GET_STATES_SUCCESS,
  payload,
});

export const getCities = (country, state) => ({
  type: types.GET_CITIES,
  country,
  state,
});

export const getCitiesSuccess = (payload) => ({
  type: types.GET_CITIES_SUCCESS,
  payload,
});

export const addVendor = (payload, token) => ({
  type: types.ADD_VENDOR,
  payload,
  token,
});

export const addVendorSuccess = (payload) => ({
  type: types.ADD_VENDOR_SUCCESS,
  payload,
});

export const updateVendor = (id, payload, token) => ({
  type: types.UPDATE_VENDOR,
  id,
  payload,
  token,
});

export const updateVendorSuccess = (payload) => ({
  type: types.UPDATE_VENDOR_SUCCESS,
  payload,
});

export const getReviews = (token, query) => ({
  type: types.GET_REVIEWS,
  token,
  query,
});

export const getReviewsSuccess = (payload) => ({
  type: types.GET_REVIEWS_SUCCESS,
  payload,
});

export const updateReview = (payload, token) => ({
  type: types.UPDATE_REVIEW,
  payload,
  token,
});

export const updateReviewSuccess = (payload) => ({
  type: types.UPDATE_REVIEW_SUCCESS,
  payload,
});

export const setRedirectTo = (payload) => ({
  type: types.SET_REDIRECT_TO,
  payload,
});

export const resetUser = () => ({
  type: types.RESET_USER,
});
