/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";

import UserList from "./UserList";
import CustomPagination from "../Pagination/pagination";
import Header from "./../../components/Headers/DefaultHeader.jsx";
import SearchField from "./../../components/SearchFields";
import * as userActions from "./../../store/actions/userActions";
import "../User/User.css";
import { showMessageError } from "../../utilities/errorHandle";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      searching: false,
      start: null,
      end: null,
    };
  }
  componentDidMount() {
    const { token, role } = this.props.auth;
    if (role === "admin") {
      this.props.getUsers(token);
    }
    this.setState({ users: this.props.user.users });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.users !== prevState.users && !prevState.searching) {
      return { users: nextProps.user.users };
    }
    if (nextProps?.user?.error !== prevState?.user?.error) {
      if (nextProps?.user?.error && nextProps?.user?.error.toString() != "") {
        showMessageError(nextProps?.user?.error.toString());
      }
      return { errorMsg: nextProps?.user?.error };
    }
    return null;
  }
  nameSearch = (value) => {
    const { users } = this.props.user;
    if (value) {
      let searchText = value?.toLowerCase();
      const filter = users.filter((user) => {
        return (
          user.email?.toLowerCase()?.includes(searchText) ||
          user.firstName?.toLowerCase()?.includes(searchText) ||
          user.lastName?.toLowerCase()?.includes(searchText)
        );
      });
      this.setState({ users: filter, searching: true });
    } else {
      this.setState({ users, searching: false });
    }
  };
  activeVendor = (id) => {
    const { token } = this.props.auth;
    this.props.activeVendor(id, token);
  };

  paginationHandler = (start, end) => {
    this.setState({
      start,
      end,
    });
  };

  render() {
    //const { users } = this.props.user;
    const { users } = this.state;
    const { role } = this.props.auth;
    const { status } = this.props.user;
    const loading = status.isLoading ? true : false;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Users</h3>
                </CardHeader>
                <div className="HeaderWrapper">
                  <SearchField onChange={this.nameSearch} />
                </div>
                {loading && (
                  <div className="text-center">
                    <Loader
                      type="Oval"
                      color="#00BFFF"
                      height={50}
                      width={50}
                      timeout={30000} //30 secs
                    />
                  </div>
                )}
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">EMAIL</th>
                      <th scope="col">NAME</th>
                      <th scope="col">ORDERS</th>
                      <th scope="col">MONEY SPENT</th>
                      <th scope="col">ACTIONS</th>
                    </tr>
                  </thead>
                  <UserList
                    users={users}
                    activeVendor={this.activeVendor}
                    start={this.state.start}
                    end={this.state.end}
                  />
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <CustomPagination
                      showPerPage={10}
                      paginationHandler={this.paginationHandler}
                      total={users.length}
                    />
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    product: state.product,
    category: state.category,
    user: state.user,
    errorMsg: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (token) => dispatch(userActions.getUsers(token)),
    activeVendor: (id, token) => dispatch(userActions.activeVendor(id, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
