import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import { Search } from "@material-ui/icons";
import { InputAdornment, TextField } from "@material-ui/core";

import CouponList from "./CouponList";
import Paginator from "../Pagination";
import Loading from "../../utilities/loading";
import Header from "./../../components/Headers/DefaultHeader.jsx";
import ConfirmationModal from "../../components/Modal/ConfirmationModal.jsx";
import * as couponActions from "./../../store/actions/couponAction";
import "../Coupon/Coupon.css";

const Coupons = ({
  history,
  getCoupons,
  coupons,
  loading,
  auth,
  deleteCoupons,
}) => {
  const [couponList, setCouponList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [id, setId] = useState(null);

  const startIndex = (page - 1) * perPage;
  const endIndex = startIndex + perPage;

  useEffect(() => {
    getCoupons();
  }, [getCoupons]);

  useEffect(() => {
    if (!searchInput) {
      setCouponList(coupons);
    }
  }, [coupons, searchInput]);

  const onSearch = useCallback(() => {
    if (searchInput) {
      const searchRegex = new RegExp(searchInput.trim(), "i");
      const filteredCoupons = coupons.filter((coupon) =>
        [
          coupon?.code,
          JSON.stringify(coupon?.targetUser),
          coupon?.discountValue,
          coupon?.maxUsage,
          coupon?.maxUsagePerUser,
          `${coupon?.adminInfo?.firstName} ${coupon?.adminInfo?.lastName}`,
          coupon?.adminInfo?.firstName,
          coupon?.adminInfo?.lastName,
          coupon?.adminInfo?.email,
          coupon?.adminInfo?.address,
          ...(coupon?.validLocations?.flatMap((location) => [
            location?.line1,
            location?.city,
            location?.state,
            location?.country,
          ]) || []),
        ].some((field) => field && searchRegex.test(field))
      );
      setCouponList(filteredCoupons);
    } else {
      setCouponList(coupons);
    }
  }, [coupons, searchInput]);

  const handleDelete = (id) => {
    setId(id);
  };

  const onConfirmDelete = () => {
    deleteCoupons(id, auth?.token);
    setId(null);
  };

  const handleEdit = (id) => {
    history.push("/dashboard/createcoupons", id);
  };

  const handleAddCoupon = () => {
    history.push("/dashboard/createcoupons");
  };

  const paginationHandler = (page) => {
    setPage(page);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Coupons Listing</h3>
              </CardHeader>
              <div className="d-flex flex-wrap justify-content-between align-items-center mx-4 mb-4">
                <TextField
                  onChange={(e) => setSearchInput(e.target.value)}
                  variant="outlined"
                  size="small"
                  placeholder="Search coupons"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="cursor-pointer">Search</span>
                      </InputAdornment>
                    ),
                  }}
                  onKeyDown={(e) => e.key === "Enter" && onSearch()}
                  className="my-3 coupon-search"
                />
                <Button onClick={handleAddCoupon} type="button">
                  Add Coupon
                </Button>
              </div>
              <div className="position-relative">
                <Loading
                  width={70}
                  height={70}
                  loading={loading}
                  className="kb-loading-table"
                />
                <Table
                  className={`${
                    loading ? "kb-overlay" : ""
                  } "align-items-center table-flush"`}
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">CODE</th>
                      <th scope="col">TARGET USER</th>
                      <th scope="col">TYPE</th>
                      <th scope="col">AMOUNT</th>
                      <th scope="col">TOTAL MAX USAGE</th>
                      <th scope="col">MAX USAGE PER USER</th>
                      <th scope="col">TARGET LOCATIONS</th>
                      <th scope="col">USE COUNTER</th>
                      <th scope="col">START DATE</th>
                      <th scope="col">EXPIRY DATE</th>
                      <th scope="col">DATE ADDED</th>
                      <th scope="col">ADMIN NAME</th>
                      <th scope="col">ADMIN EMAIL</th>
                      <th scope="col">ADMIN ADDRESS</th>
                      <th scope="col">ACTIONS</th>
                    </tr>
                  </thead>
                  <CouponList
                    coupons={couponList}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    start={startIndex}
                    end={endIndex}
                  />
                </Table>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Paginator
                    showPerPage={10}
                    paginationHandler={paginationHandler}
                    total={couponList?.length}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {id && (
        <ConfirmationModal
          title="Are you sure you want to delete this coupon?"
          onConfirm={onConfirmDelete}
          onClose={() => setId(null)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  coupons: state.coupon.coupons,
  loading: state.coupon.loading,
  error: state.coupon.error,
});

const mapDispatchToProps = (dispatch) => ({
  getCoupons: () => dispatch(couponActions.getCoupons()),
  deleteCoupons: (id, token) =>
    dispatch(couponActions.deleteCoupons(id, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);
