import React from "react";
import { BiRocket, BiFile, BiPencil } from "react-icons/bi";
import { MdOutlineDateRange, MdPendingActions } from "react-icons/md";
import { TbApps, TbPigMoney, TbTruckDelivery } from "react-icons/tb";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FcApproval } from "react-icons/fc";
import { GiReceiveMoney } from "react-icons/gi";
import { RiRefundFill } from "react-icons/ri";
import { Button } from "react-bootstrap";

import Map from "./Common/Map";
import Normal from "./Common/Normal";
import WithActions from "./Common/WithActions";
import Expendable from "./Common/Expendable";
import Message from "./Common/Message";
import {
  ACTIVITY_TYPE,
  activityCustomDate,
  deliveryDate,
} from "../../../utilities";

export const menus = [
  "Activity",
  "Details",
  "Requirements",
  "Addons",
  "Delivery",
];

export const icons = {
  [ACTIVITY_TYPE.ORDER_PLACED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(237 233 254)" }}
        className="rounded-circle"
      >
        <BiFile
          style={{ color: "rgb(167 139 250)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_ORDER]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(237 233 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(167 139 250)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.SUBMIT_REQUIREMENT]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(224 231 255)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(129 140 248)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.REQUIREMENT_SUBMITTED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(224 231 255)" }}
        className="rounded-circle"
      >
        <BiPencil
          style={{ color: "rgb(129 140 248)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.ORDER_CONFIRMED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D", padding: "6px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.PRODUCT_CONFIRMED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D", padding: "6px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.APPOINTMENT_BOOKED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(237 233 254)" }}
        className="rounded-circle"
      >
        <BiFile
          style={{ color: "rgb(167 139 250)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.APPOINTMENT_CONFIRMED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D" }}
          className="activity-icon p-1"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.NEW_APPOINTMENT_BOOKED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(237 233 254)" }}
        className="rounded-circle"
      >
        <BiFile
          style={{ color: "rgb(167 139 250)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_PICKUP_ADDRESS]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D" }}
          className="activity-icon p-1"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.ADDRESS_CONFIRMED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D" }}
          className="activity-icon p-1"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.STORE_MAP]: {
    icon: (
      <div
        style={{
          backgroundColor: "rgb(254 226 226)",
          height: "40px",
          width: "44px",
        }}
        className="d-flex justify-content-center align-items-center rounded-circle"
      >
        <FaMapMarkerAlt
          style={{ color: "rgb(248 113 113)", height: "24px", width: "24px" }}
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.NEW_ADDONS]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <TbApps
          style={{ color: "rgb(59 130 246)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_PRODUCT]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)" }}
          className="activity-icon p-2"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.DELIVERY_DATE_INFO]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.NEW_PICKUP_DATE]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdOutlineDateRange
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.DELIVER_ORDER]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.ORDER_DELIVERED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <BiRocket style={{ color: "#019B5D" }} className="activity-icon p-2" />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_DELIVERY]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.GIVE_VENDOR_TIP]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <TbPigMoney
          style={{ color: "rgb(96 165 250)", padding: "6px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.GAVE_VENDOR_TIP]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <GiReceiveMoney
          style={{ color: "rgb(96 165 250)", padding: "6px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.AMOUNT_REFUNDED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <RiRefundFill
          style={{ color: "rgb(96 165 250)", padding: "6px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_DELIVERY_PRICE]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.DELIVERY_PRICE_CONFIRMED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D" }}
          className="activity-icon p-1"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.SELECT_PICKUP_DATE]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdOutlineDateRange
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_PICKUP_DATE]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.PICKUP_DATE_PROVIDED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.PICKUP_DATE_CONFIRMED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D" }}
          className="activity-icon p-1"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.CONFIRM_PICKUP]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <MdPendingActions
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.PICKUP_DATE_INFO]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(219 234 254)" }}
        className="rounded-circle"
      >
        <TbTruckDelivery
          style={{ color: "rgb(59 130 246)", padding: "8px" }}
          className="activity-icon"
        />
      </div>
    ),
  },
  [ACTIVITY_TYPE.REVIEW_SUBMITTED]: {
    icon: (
      <div
        style={{ backgroundColor: "rgb(220 252 231)" }}
        className="rounded-circle"
      >
        <FcApproval
          style={{ color: "#019B5D" }}
          className="activity-icon p-1"
        />
      </div>
    ),
  },
};

export const CustomizedActivity = ({
  forTheClient,
  forTheVendor,
  auth,
  orderDetails,
  allTypes,
  activity,
  product,
  order,
}) => {
  const { type, client, forClient, vendor, forVendor } = activity;

  if (!type) return <div></div>;

  if (type === ACTIVITY_TYPE.ORDER_PLACED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } placed an order`}
        />
      );
    else if (forClient && forTheClient)
      return <Normal {...activity} text={`Your order is placed`} />;
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.CONFIRM_ORDER) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={
            "Customer has not approved product yet. please <span class='text-danger'>DO NOT START</span> the order."
          }
        />
      );
    else if (forClient && forTheClient)
      return (
        <WithActions
          {...activity}
          text={"Please approve your product, so we can process your order"}
          allTypes={allTypes}
          actionType="confirmOrder"
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.REQUIREMENT_SUBMITTED) {
    if (forVendor && forTheVendor)
      return (
        <Expendable
          {...activity}
          text={`${client?.firstName ?? ""} submitted the requirements`}
        />
      );
    else if (forClient && forTheClient)
      return <Expendable {...activity} text="You submitted the requirements" />;
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.ORDER_CONFIRMED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } approved the product, please <span style="color:#019B5D">START PROCESSING</span> the order.`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`You have approved the product on: <br/> ${activityCustomDate(
            activity?.createdAt
          )}`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.PRODUCT_CONFIRMED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } approved the product, please <span style="color:#019B5D">START PROCESSING</span> the order.`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`You have approved the product on: <br/> ${activityCustomDate(
            activity?.createdAt
          )}`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.DELIVERY_DATE_INFO) {
    if (forVendor && forTheVendor)
      return (
        <WithActions
          {...activity}
          text={
            orderDetails?.pickupChoice?.mode.includes("Pickup")
              ? `The customer is waiting for you to confirm the pickup date`
              : `The customer will pickup the product on: <br> ${deliveryDate(
                  orderDetails?.pickupDate,
                  false,
                  false,
                  { addons: orderDetails?.addons }
                )}`
          }
          allTypes={allTypes}
          actionType={
            orderDetails?.pickupChoice?.mode.includes("Pickup") &&
            "confirmPickupDate"
          }
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={
            orderDetails?.pickupChoice?.mode.includes("Pickup")
              ? "Please wait for vendor to confirm pickup date"
              : `Your product will be ready for pickup on: <br/> ${deliveryDate(
                  orderDetails?.pickupDate,
                  false,
                  false,
                  { addons: orderDetails?.addons }
                )}`
          }
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.PICKUP_DATE_INFO) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`The customer will pickup the product on: <br> ${activityCustomDate(
            orderDetails?.pickupDate
          )}`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`Your product will be ready for pickup on: <br/> ${activityCustomDate(
            orderDetails?.pickupDate
          )}`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.APPOINTMENT_BOOKED) {
    if (forVendor && forTheVendor)
      return (
        <WithActions
          {...activity}
          text={`${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } has scheduled an appointment to visit your store on: <br/> ${activityCustomDate(
            activity?.appointmentDate
          )}`}
          allTypes={allTypes}
          actionType="confirmAppointment"
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`You have scheduled an appointment to visit the store on: <br/> ${activityCustomDate(
            activity?.appointmentDate
          )}`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.APPOINTMENT_CONFIRMED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`You have confirmed ${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          }'s appointment to visit the store on: <br/> ${activityCustomDate(
            activity?.appointmentDate
          )}`}
        >
          <div className="flex gap-2 items-center mt-2">
            <b>Address:</b>
            <div className="font-italic">
              {orderDetails?.owner?.storeAddress}
            </div>
          </div>
          {activity?.status === ACTIVITY_TYPE.ADDRESS_CONFIRMED ? (
            <div className="mt-2 py-2 font-italic text-gray-400 text-[16px] rounded-md">
              Address Confirmed
            </div>
          ) : (
            <div className="flex gap-3 mt-2">
              <Button className="btn btn-success text-white">
                Confirm Pickup Address
              </Button>
              <Button className="btn btn-warning text-white">
                Update Store Address
              </Button>
            </div>
          )}
        </Normal>
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`Your vendor has confirmed your appointment to visit the store on: <br/> ${activityCustomDate(
            activity?.appointmentDate
          )}`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.CONFIRM_PICKUP_ADDRESS) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`Please confirm the adddress for ${client?.firstName ?? ""} ${
            client?.lastName
          } to visit the store on: <br/> ${activityCustomDate(
            activity?.appointmentDate
          )}`}
        >
          <div className="flex gap-2 items-center mt-2">
            <b>Address:</b>
            <div className="font-italic">
              {orderDetails?.owner?.storeAddress}
            </div>
          </div>
          {activity?.status === ACTIVITY_TYPE.ADDRESS_CONFIRMED ? (
            <div className="mt-2 py-2 font-italic text-gray-400 text-[16px] rounded-md">
              Address Confirmed
            </div>
          ) : (
            <div className="flex gap-3 mt-2">
              <Button disabled className="bg-success text-white">
                Confirm Pickup Address
              </Button>
              <Button disabled className="bg-orange-500 text-white">
                Update Store Address
              </Button>
            </div>
          )}
        </Normal>
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`Your vendor has scheduled you a new appointment to visit the store on: <br/> <span class="bg-yellow-400"> ${activityCustomDate(
            activity?.appointmentDate
          )} </span>`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.ADDRESS_CONFIRMED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`You have confirmed the address for the customer to visit the store on:  <br/> <span class="bg-yellow-400"> ${activityCustomDate(
            activity?.appointmentDate
          )} </span>`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.NEW_APPOINTMENT_BOOKED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`You have scheduled new appointment for ${
            client?.firstName ?? ""
          } ${
            client?.lastName ?? ""
          } to visit the store on: <br/> ${activityCustomDate(
            activity?.appointmentDate
          )}`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <WithActions
          {...activity}
          client={client}
          text={`We are sorry, your appointment date is not available.`}
          allTypes={allTypes}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.STORE_MAP) {
    if (forClient && forTheClient)
      return (
        <Map
          {...activity}
          text={`Click on the map to get directions to the store`}
          product={product}
          order={orderDetails}
        />
      );
    return <div></div>;
  } else if (type === ACTIVITY_TYPE.NEW_ADDONS) {
    if (forVendor && forTheVendor)
      return (
        <Expendable
          {...activity}
          text={`${client?.firstName ?? ""} ${
            client?.lastName ?? ""
          } has submitted the addons`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Expendable {...activity} text={`You have submitted the addons`} />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.CONFIRM_PRODUCT) {
    if (forClient && forTheClient)
      return (
        <WithActions
          {...activity}
          allTypes={allTypes}
          text="Please approve your product once you visit the store"
          actionType="confirmProduct"
        />
      );

    return <div></div>;
  } else if (type === ACTIVITY_TYPE.NEW_PICKUP_DATE) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          allTypes={allTypes}
          text={`The customer will pickup the product on: <br> ${activityCustomDate(
            orderDetails?.pickupDate
          )} </br>`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          allTypes={allTypes}
          text={`Your product will be ready for pickup on: <br> ${activityCustomDate(
            orderDetails?.pickupDate
          )} </br>`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.DELIVER_ORDER) {
    if (forVendor && forTheVendor)
      return (
        <WithActions
          {...activity}
          allTypes={allTypes}
          text="Customer is waiting for you to deliver the order"
          actionType="deliverOrder"
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.ORDER_DELIVERED) {
    if (forVendor && forTheVendor)
      return <Normal {...activity} text="You have delivered the order" />;
    else if (forClient && forTheClient)
      return (
        <WithActions
          {...activity}
          allTypes={allTypes}
          text="Your order has been delivered successfully."
          actionType="acceptDelivery"
        />
      );
    else return <div></div>;
  } else if (type.includes(ACTIVITY_TYPE.CONFIRM_DELIVERY)) {
    if (forVendor && forTheVendor)
      return (
        <WithActions
          {...activity}
          allTypes={allTypes}
          text="Have you delivered the order?"
          textAfterDate={activity?.confirmDeliveryAction}
          actionType="confirmDelivery"
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.GIVE_VENDOR_TIP) {
    if (forClient && forTheClient)
      return (
        <WithActions
          {...activity}
          allTypes={allTypes}
          text="Show your gratitude by leaving a tip for your vendor."
          actionType="tips"
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.GAVE_VENDOR_TIP) {
    if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          allTypes={allTypes}
          text="You left tips for your vendor."
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.AMOUNT_REFUNDED) {
    return (
      <Normal {...activity} allTypes={allTypes} text="Order amount refunded" />
    );
  } else if (type.split("-")?.[0] === ACTIVITY_TYPE.NEW_MESSAGE) {
    const { senderId } = activity;
    if (forVendor && forTheVendor)
      return (
        <Message
          {...activity}
          name={
            vendor?._id === senderId
              ? "Me"
              : `${vendor?.firstName} ${vendor?.lastName}`
          }
          profilePic={vendor?.profilePic}
          vendor={vendor}
          forVendor={true}
          auth={auth}
        />
      );
    else if (forTheClient)
      return (
        <Message
          {...activity}
          name={
            client?._id === senderId
              ? "Me"
              : `${client?.firstName} ${client?.lastName}`
          }
          profilePic={client?.profilePic}
          client={client}
          forClient={true}
          auth={auth}
        />
      );

    if (forClient && forTheClient)
      return (
        <Message
          {...activity}
          name={
            client._id === senderId
              ? "Me"
              : `${client?.firstName} ${client?.lastName}`
          }
          profilePic={client?.profilePic}
          client={client}
          forClient={true}
          auth={auth}
        />
      );
    else if (forTheVendor)
      return (
        <Message
          {...activity}
          name={
            vendor?._id === senderId
              ? "Me"
              : `${vendor?.firstName} ${vendor?.lastName}`
          }
          profilePic={vendor?.profilePic}
          vendor={vendor}
          forVendor={true}
          auth={auth}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.CONFIRM_DELIVERY_PRICE) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          allTypes={allTypes}
          text="Please confirm delivery option."
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.DELIVERY_PRICE_CONFIRMED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          allTypes={allTypes}
          text="<span>Thank You for confirming the delivery option.</span></br> <span>We are sending the offer to the buyer.</span> </br> <span>Please wait for buyer response. </span>"
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          allTypes={allTypes}
          text="<span>Vendor has provided your delivery option.</span></br> <span>Please accept and confirm </span>"
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.SELECT_PICKUP_DATE) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          allTypes={allTypes}
          text={`Please wait for ${client?.firstName} to select pickup date`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <WithActions
          {...activity}
          text="<span>Please select pickup date</span>"
          actionType="confirmPickupDate"
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.CONFIRM_PICKUP_DATE) {
    if (forVendor && forTheVendor)
      return (
        <Normal {...activity} text="Please confirm pickup date">
          {activity?.status === ACTIVITY_TYPE.PICKUP_DATE_PROVIDED ? (
            <div className="py-2 font-italic text-gray-400 text-[16px] rounded-md">
              Provided new pickup date options
            </div>
          ) : activity?.status === ACTIVITY_TYPE.PICKUP_DATE_CONFIRMED ? (
            <div className="py-2 font-italic text-gray-400 text-[16px] rounded-md">
              Pickup Date Confirmed
            </div>
          ) : (
            <div></div>
          )}
        </Normal>
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`Please wait for ${vendor?.firstName} to confirm pickup date`}
        />
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.PICKUP_DATE_PROVIDED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`Please wait for ${client?.firstName} to select new pickup date`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`${vendor.firstName} has provided new pickup dates for you, <br/> Please select new pickup date`}
        >
          {activity?.status === ACTIVITY_TYPE.PICKUP_DATE_CONFIRMED ? (
            <div className="py-2 font-italic text-gray-400 text-[16px] rounded-md">
              Pickup Date Confirmed
            </div>
          ) : (
            <div></div>
          )}
        </Normal>
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.PICKUP_DATE_CONFIRMED) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`${vendor?.firstName} confirmed order pickup for ${
            client.firstName
          } ${
            client.lastName
          } on: <br/> <b style="color:#60a5fa;font-style:italic;">${activityCustomDate(
            orderDetails?.pickupDate
          )}</b>.`}
        />
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text={`${vendor?.firstName} confirmed order pickup for ${
            client.firstName
          } ${
            client.lastName
          } on: <br/> <b style="color:#60a5fa;font-style:italic;">${activityCustomDate(
            orderDetails?.pickupDate
          )}</b>.`}
        />
      );
    else return <div></div>;
  } else if (type.includes(ACTIVITY_TYPE.CONFIRM_PICKUP)) {
    if (forVendor && forTheVendor)
      return (
        <Normal
          {...activity}
          text={`Did ${client?.firstName} ${client?.lastName} pickup the order?`}
          textAfterDate={activity?.vendorPickupAction}
        >
          {activity?.status === ACTIVITY_TYPE.PICKUP_CONFIRMED &&
          activity?.vendorPickupAction ? (
            activity?.vendorPickupAction === "no" ? (
              ""
            ) : (
              <div className="mt-2 py-2 font-italic text-gray-400 text-[16px] rounded-md">
                Thank you for confirming order pickup.
              </div>
            )
          ) : (
            <div className="flex gap-3">
              <Button disabled className="btn btn-primary text-white w-24">
                Yes
              </Button>
              <Button disabled className="btn btn-danger text-white w-24">
                No
              </Button>
            </div>
          )}
        </Normal>
      );
    else if (forClient && forTheClient)
      return (
        <Normal
          {...activity}
          text="Did you pickup the order?"
          textAfterDate={activity?.clientPickupAction}
        >
          {activity?.status === ACTIVITY_TYPE.PICKUP_CONFIRMED &&
          activity?.clientPickupAction ? (
            activity?.clientPickupAction === "no" ? (
              ""
            ) : (
              <div className="mt-2 py-2 font-italic text-gray-400 text-[16px] rounded-md">
                Thank you for confirming order pickup
              </div>
            )
          ) : (
            <div className="flex gap-3">
              <Button disabled className="bg-primary text-white w-24">
                Yes
              </Button>
              <Button disabled className="bg-danger text-white w-24">
                No
              </Button>
            </div>
          )}
        </Normal>
      );
    else return <div></div>;
  } else if (type === ACTIVITY_TYPE.REVIEW_SUBMITTED) {
    return (
      <Normal
        {...activity}
        text={`Your review has been submitted. </br>Thank you!`}
      />
    );
  } else {
    return <div></div>;
  }
};

export default CustomizedActivity;
