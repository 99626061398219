import * as types from "../types/user";

const initialState = {
  vendors: [],
  vendor: {},
  users: [],
  media: [],
  roles: [],
  countries: [],
  states: [],
  cities: [],
  reviews: [],
  notifications: [],
  notificationCount: 0,
  status: { isLoaded: true, isLoading: false },
  loading: false,
  error: "",
  succmsg: "",
  redirectTo: "",
};

const vendorReducers = (state = initialState, actions) => {
  switch (actions.type) {
    // Failed List Articles
    case types.LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.REMOVE_SUCCESS_MSG: {
      return {
        ...state,
        loading: false,
        succmsg: "",
      };
    }
    case types.LIST_USER_FAILED: {
      return {
        ...state,
        loading: false,
        error: actions.message,
      };
    }
    case types.ADD_UPDATE_USER_FAILED: {
      return {
        ...state,
        loading: false,
        error: actions.message,
      };
    }
    case types.UPDATE_VENDOR_STATUS: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.UPDATE_VENDOR_STATUS_SUCCESS: {
      const vendors = [...state.vendors];
      const index = vendors.findIndex((e) => e._id === actions.data.id);
      vendors[index].status = actions.data.status;
      return {
        ...state,
        loading: false,
        succmsg: actions.message,
        vendors: vendors,
      };
    }
    case types.DELETE_VENDOR:
    case types.ACTIVATE_VENDOR:
      return {
        ...state,
        loading: true,
        status: { isLoading: true, isLoaded: false },
      };
    case types.UPDATE_USER_SUCCESS:
      const updatedUser = state.users.map((item) => {
        if (item._id == actions.payload._id) {
          return { ...item, role: actions.payload.role };
        } else {
          return item;
        }
      });
      return {
        ...state,
        users: updatedUser,
      };
    case types.GET_VENDORS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: { isLoading: false, isLoaded: true },
        vendors: actions.vendors,
      };
    case types.GET_SINGLE_VENDOR_SUCCESS:
      return {
        ...state,
        status: { isLoading: false, isLoaded: true },
        vendor: actions.vendor,
      };
    case types.DELETE_VENDOR_SUCCESS: {
      const vendors = [...state.vendors];
      const index = vendors.findIndex((e) => e._id === actions.payload._id);
      vendors[index].activate = actions.payload.activate;
      return {
        ...state,
        loading: false,
        status: { isLoading: false, isLoaded: true },
        vendors,
      };
    }
    case types.ACTIVATE_USER_SUCCESS: {
      const users = [...state.users];
      const index = users.findIndex((e) => e._id === actions.data._id);
      users[index].activate = actions.data.activate;
      return {
        ...state,
        loading: false,
        status: { isLoading: false, isLoaded: true },
        users,
      };
    }
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        status: { isLoading: false, isLoaded: true },
        users: actions.user,
        error: false,
      };
    case types.GET_ALL_USERS_SUCCESS:
      const filtered = actions.payload.filter(
        (user) => user.role?.name !== "vendor" && user.role?.name !== "user"
      );
      return {
        ...state,
        users: filtered,
      };
    case types.ADD_USER_SUCCESS: {
      const payload = actions.payload;
      return {
        ...state,
        users: [...state.users, payload],
      };
    }
    case types.UPDATE_USER_BY_ADMIN_SUCCESS: {
      const payload = actions.payload;
      const updatedUser = state.users.map((user) => {
        if (user?._id == payload._id) {
          return { ...user, ...payload };
        } else {
          return user;
        }
      });
      return {
        ...state,
        users: updatedUser,
      };
    }
    case types.GET_MEDIA:
      return {
        ...state,
        loading: true,
      };
    case types.GET_MEDIA_SUCCESS:
      return {
        ...state,
        status: { isLoading: false, isLoaded: true },
        loading: false,
        media: actions.medias,
      };
    case types.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: actions.roles,
      };
    case types.DELETE_USER_SUCCESS:
      let users = [...state.users];
      let index = users.findIndex((e) => e._id === actions.updateUser._id);
      users[index].activate = actions.updateUser.activate;
      return {
        ...state,
        status: { isLoading: false, isLoaded: true },
        users,
      };
    case types.DELETE_USER_PERMANENTLY_SUCCESS: {
      const filteredUsers = state.users.filter(
        (e) => e._id !== actions.payload._id
      );
      return {
        ...state,
        status: { isLoading: false, isLoaded: true },
        users: filteredUsers,
      };
    }
    case types.DELETE_VENDOR_SUCCESS:
      let vendors = [...state.vendors];
      let indexId = vendors.findIndex((e) => e._id === actions.updateUser._id);
      vendors[indexId].activate = actions.updateUser.activate;
      return {
        ...state,
        status: { isLoading: false, isLoaded: true },
        vendors,
      };
    case types.DELETE_MEDIA:
    case types.TRASH_MEDIA: {
      return { ...state, loading: true };
    }
    case types.DELETE_MEDIA_SUCCESS:
    case types.TRASH_MEDIA_SUCCESS: {
      const payload = actions.payload;
      const medias = state?.media ?? [];
      const filteredMedia = medias.filter((e) => e._id !== payload?._id);
      return (state = {
        ...state,
        media: filteredMedia,
        loading: false,
      });
    }
    case types.DELETE_VENDOR_SUCCESS:
    case types.TRASH_STORE_SUCCESS: {
      const payload = actions.payload;
      const vendors = state?.vendors ?? [];
      const filteredVendors = vendors.filter((e) => e._id !== payload?._id);
      return (state = {
        ...state,
        vendors: filteredVendors,
        loading: false,
      });
    }
    case types.ADD_FLAG_TO_MEDIA_SUCCESS: {
      const payload = actions.payload;
      const medias = state?.media ?? [];
      const filteredMedia = medias.map((ele) => {
        if (ele?._id == payload?._id) {
          return { ...ele, ...payload };
        } else {
          return ele;
        }
      });
      return {
        ...state,
        media: filteredMedia,
      };
    }
    case types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: actions.payload,
      };
    case types.UNREAD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationCount: actions.payload,
      };
    case types.MARK_NOTIFICATION_SUCCESS: {
      const payload = actions.payload;
      const notifications = state.notifications.map((notification) => {
        if (payload._id == notification._id) {
          return { ...notification, status: "READ" };
        } else {
          return notification;
        }
      });
      return {
        ...state,
        notifications,
        notificationCount: state.notificationCount - 1,
      };
    }
    case types.MARK_ALL_NOTIFICATIONS_SUCCESS: {
      const notifications = state.notifications.map((notification) => {
        return { ...notification, status: "READ" };
      });
      return {
        ...state,
        notifications,
        notificationCount: 0,
      };
    }
    case types.GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: actions.payload?.map((country) => ({
          label: country.name,
          value: country.name,
        })),
      };
    }
    case types.GET_STATES_SUCCESS: {
      return {
        ...state,
        states: actions.payload.map((state) => ({
          label: state.name,
          value: state.name,
        })),
      };
    }
    case types.GET_CITIES_SUCCESS: {
      return {
        ...state,
        cities: actions.payload?.map((city) => ({
          label: city,
          value: city,
        })),
      };
    }
    case types.ADD_VENDOR_SUCCESS: {
      return {
        ...state,
        vendors: [actions.payload, ...state.vendors],
      };
    }
    case types.UPDATE_VENDOR_SUCCESS: {
      const updatedVendor = state?.vendors?.map((vendor) => {
        if (vendor?._id === actions?.payload?._id) {
          return {
            ...vendor,
            ...actions.payload,
          };
        } else {
          return vendor;
        }
      });
      return { ...state, vendors: updatedVendor };
    }
    case types.GET_REVIEWS_SUCCESS: {
      return {
        ...state,
        reviews: actions.payload,
      };
    }
    case types.SET_REDIRECT_TO: {
      return {
        ...state,
        redirectTo: actions.payload,
      };
    }

    case types.RESET_USER: {
      return {
        ...initialState,
        redirectTo: state.redirectTo
      };
    }
    default:
      return state;
  }
};

export default vendorReducers;
