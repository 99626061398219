import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import moment from "moment";

import Comments from "./Comments";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import {
  deleteComment,
  getOneArticle,
} from "../../store/actions/articleActions";
import "./Articles.scss";

const ArticleContent = (props) => {
  const {
    auth,
    article,
    match,
    directContent,
    videoUrls,
    mediaUrls,
    history,
    deleteComment,
    getOneArticle,
  } = props;
  const slug = match.params.slug;
  const content = directContent ?? article;
  const [id, setId] = useState("");

  useEffect(() => {
    getOneArticle(slug);
  }, [slug]);

  const onDelete = () => {
    deleteComment(id, auth?.token);
    setId("");
  };

  return (
    <div className={`article-content ${!directContent ? "main" : ""}`}>
      <div className={`${!directContent ? "container" : ""}`}>
        <div className="header">
          {directContent ? (
            <h1>Article preview</h1>
          ) : (
            <Button onClick={() => history.goBack()}>Go back</Button>
          )}
        </div>
        <div className="name">{content?.name}</div>
        <div className="mt-3">
          <div className="w-full text-lg text-slate-800">
            By{" "}
            {auth
              ? auth?.name
              : `${content?.owner?.firstName} ${content?.owner?.lastName}`}
            {" — "} Published on{" "}
            {moment(content?.updatedAt ?? content?.date).format(
              "MMMM DD, YYYY"
            )}
          </div>
        </div>
        {content?.cover && (
          <div className="w-100 poster-image">
            <img
              height="100%"
              width="100%"
              className="rounded-xl"
              src={
                typeof content?.cover == "string"
                  ? content?.cover
                  : URL.createObjectURL(content?.cover)
              }
              loading="lazy"
            />
          </div>
        )}
        <div className="poster-description font-light text-lg leading-8 tracking-wider font-[Roboto] mt-4">
          {content?.description}
        </div>
        {(mediaUrls ?? content?.pic)?.map((data, index) => {
          return (
            <div className="mt-5" key={index}>
              <div className="w-100 media-image">
                <img
                  height="100%"
                  width="100%"
                  className="rounded-xl"
                  src={data.image ? URL.createObjectURL(data.image) : data.url}
                  alt={data.title}
                  onError={(e) => {
                    e.target.onerror = null;
                  }}
                  loading="lazy"
                />
              </div>
              <div className="mt-12">
                <div className="media-title text-2xl font-medium">
                  {data.title}
                </div>
                <div className="media-description font-light text-lg leading-8 tracking-wider font-[Roboto] mt-4">
                  {data.description}
                </div>
              </div>
            </div>
          );
        })}
        {(videoUrls ?? content?.video)?.map((data, index) => {
          return (
            <div className="video-wrapper" key={index}>
              <div className="w-100">
                <div className="article-video">
                  <iframe
                    height="380"
                    className="w-100 overflow-hidden rounded-xl"
                    src={data.url}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="mt-12">
                <div className="text-2xl font-medium video-title">
                  {data.title}
                </div>
                <div className="video-description font-light text-lg leading-8 tracking-wider font-[Roboto] mt-4">
                  {data.description}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {content?.comments?.length > 0 && (
        <Comments comments={content?.comments ?? []} setId={setId} />
      )}
      {id && (
        <ConfirmationModal
          title="Are you sure you want to delete this comment?"
          onClose={() => setId("")}
          onConfirm={onDelete}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    article: state.article.article,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteComment: (id, token) => dispatch(deleteComment(id, token)),
    getOneArticle: (slug) => dispatch(getOneArticle(slug)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ArticleContent));
