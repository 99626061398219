import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import { GoLocation } from "react-icons/go";
import { IoMdCall, IoIosAt } from "react-icons/io";
import { FcPlus, FcOk } from "react-icons/fc";
import { BiEnvelope, BiCart } from "react-icons/bi";
import { BsEye, BsPencil } from "react-icons/bs";
import { FaChartLine } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";

import Header from "../../components/Headers/DefaultHeader.jsx";
import SearchField from "./../../components/SearchFields";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import Paginator from "../Pagination";
import * as userActions from "../../store/actions/userActions";
import Loading from "../../utilities/loading.js";
import { formatDate } from "../../utilities";
import "./Vendor.css";

const statusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
];

const grossSale = (vendor) => {
  return vendor?.orderItems?.reduce(
    (acc, item) => acc + (item?.totalPrice || 0),
    0
  );
};

const StoreVendor = (props) => {
  const {
    auth,
    vendors,
    getVendor,
    trashStore,
    updateVendorStatus,
    loading,
    history,
    location,
  } = props;
  const [vendorList, setVendorList] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [storeDetails, setStoreDetails] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const startIndex = (page - 1) * perPage;
  const endIndex = startIndex + perPage;

  useEffect(() => {
    if (location?.state !== "noRefetch" && auth?.token)
      getVendor(auth?.token, { vendor: true });
    else history.push("/dashboard/vendorstore");
  }, []);

  useEffect(() => {
    setVendorList(vendors);
  }, [vendors]);

  const nameSearch = (value) => {
    if (value) {
      const searchRegex = new RegExp(value, "i");
      const filter = vendors.filter((vendor) =>
        [
          vendor?.email,
          vendor?.contact,
          vendor?.storeAddress,
          vendor?.storeName,
        ].some((field) => field && searchRegex.test(field))
      );
      setVendorList(filter);
    } else {
      setVendorList(vendors);
    }
  };

  const filterByStatus = (option) => {
    if (option?.value) {
      const filter = vendors.filter((vendor) => option.value === vendor.status);
      setVendorList(filter);
    } else {
      setVendorList(vendors);
    }
  };

  const handleAddVendor = () => {
    history.push("/dashboard/add-vendor");
  };

  const handleEdit = (vendorId) => {
    history.push("/dashboard/add-vendor", vendorId);
  };

  const onDeleteVendor = (store) => {
    setStoreDetails(store);
    setShowDeleteModal(true);
  };

  const onConfirmDeleteVendor = () => {
    trashStore(storeDetails._id, { deleted: true }, auth?.token);
    setShowDeleteModal(false);
  };

  const viewVendorPage = (id) => {
    window.location.href = "/dashboard/vendor-store/" + id;
  };

  const paginationHandler = (page) => {
    setPage(page);
  };

  const changeStatus = (status, id) => {
    const { token } = auth;
    updateVendorStatus({ status, id }, token);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Store Vendor</h3>
              </CardHeader>
              <div className="HeaderWrapper align-items-center">
                <SearchField onChange={nameSearch} />
                <Button onClick={handleAddVendor} type="button">
                  Add Vendor
                </Button>
              </div>
              <div className="position-relative">
                <Loading
                  width={70}
                  height={70}
                  loading={loading}
                  className="kb-loading-table"
                />
                <Table
                  className={`${
                    loading ? "kb-overlay" : ""
                  } "align-items-center table-flush"`}
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th></th>
                      <th></th>
                      <th scope="col">VERIFICATION PROFILE</th>
                      <th scope="col">STORE</th>
                      <th scope="row">
                        <div>Vendor Status</div>
                        <div style={{ width: 150 }}>
                          <ReactSelect
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                height: 40,
                              }),
                            }}
                            options={statusOptions}
                            placeholder="Select status"
                            onChange={filterByStatus}
                            isClearable
                          />
                        </div>
                      </th>
                      <th scope="col">GROSS SALES</th>
                      <th scope="col">EARNINGS</th>
                      <th scope="col">WITHDRAWAL</th>
                      <th scope="col">Register Date</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vendorList
                      .slice(startIndex, endIndex)
                      .map((vendorStore) => {
                        return (
                          <tr key={vendorStore._id}>
                            <td>
                              <div>
                                <FcPlus className="vendorPlus" />
                              </div>
                              <br />
                              <div>
                                <FcOk className="vendorPlus" />
                              </div>
                            </td>
                            <td>
                              <div>
                                <BiEnvelope className="vendorPlus" />
                              </div>
                            </td>
                            <td
                              style={{
                                minWidth: "300px",
                                maxWidth: "300px",
                                textWrap: "wrap",
                              }}
                            >
                              <IoIosAt
                                style={{ size: "40px", color: "#5DADE2" }}
                              />
                              {vendorStore.email}
                              <span>
                                <br />
                                <IoMdCall
                                  style={{ size: "40px", color: "#5DADE2" }}
                                />
                                {vendorStore.contact}
                                <br />
                                <GoLocation
                                  style={{ size: "40px", color: "#5DADE2" }}
                                />
                                {vendorStore.storeAddress}
                              </span>
                            </td>
                            <td>{vendorStore.storeName}</td>
                            <td style={{ width: 130 }}>
                              <ReactSelect
                                options={statusOptions}
                                value={statusOptions.find(
                                  (option) => option.value == vendorStore.status
                                )}
                                onChange={(option) => {
                                  changeStatus(option.value, vendorStore._id);
                                }}
                              />
                            </td>
                            <td>
                              <span style={{ color: "red" }}>
                                ${grossSale(vendorStore)}
                              </span>
                            </td>
                            <td>
                              <span style={{ color: "red" }}>
                                ${grossSale(vendorStore)}
                              </span>
                            </td>
                            <td>
                              <span style={{ color: "red" }}>
                                ${grossSale(vendorStore)}
                              </span>
                            </td>
                            <td>
                              {formatDate(
                                vendorStore?.createAt ?? vendorStore?.createdAt
                              )}
                            </td>
                            <td>
                              <BsEye
                                className="vendorPlus"
                                onClick={() =>
                                  viewVendorPage(vendorStore._id, auth.token)
                                }
                              />
                              <BsPencil
                                className="vendorPlus"
                                onClick={() =>
                                  handleEdit(vendorStore._id, auth.token)
                                }
                              />
                              <BiCart className="vendorPlus" />

                              <div className="mt-10">
                                <FaChartLine className="vendorPlus" />
                                <ImCancelCircle
                                  onClick={() => onDeleteVendor(vendorStore)}
                                  className="vendorPlus"
                                />
                              </div>

                              {/* <div className="mt-10">
                              <BsPower
                                onClick={() =>
                                  onDeleteVendor(vendorStore._id, auth.token)
                                }
                                className="vendorPlus"
                              />
                            </div> */}
                              {/* <div className="mt-10">
                              {vendorStore?.activate ? (
                                <Button
                                  className="btn btn-sm btn-info"
                                  disabled={loading}
                                  onClick={() =>
                                    onDeleteVendor(vendorStore._id, auth.token)
                                  }
                                >
                                  Inactive
                                </Button>
                              ) : (
                                <Button
                                  className="btn btn-sm btn-info"
                                  disabled={loading}
                                  onClick={() =>
                                    onDeleteVendor(vendorStore._id, auth.token)
                                  }
                                >
                                  Active
                                </Button>
                              )}
                            </div> */}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Paginator
                      page={page}
                      perPage={perPage}
                      total={vendors?.length ?? 0}
                      onPageChange={paginationHandler}
                    />
                  </nav>
                </CardFooter>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
      {showDeleteModal && (
        <ConfirmationModal
          title="Are you sure you want to delete?"
          onClose={() => setShowDeleteModal(false)}
          onConfirm={onConfirmDeleteVendor}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    vendors: state.user.vendors,
    loading_: state.user.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendor: (token, query) => dispatch(userActions.getVendor(token, query)),
    removeSuccessMessage: () => dispatch(userActions.removeSuccessMessage()),
    trashStore: (id, payload, token) =>
      dispatch(userActions.trashStore(id, payload, token)),
    updateVendorStatus: (data, token) =>
      dispatch(userActions.updateVendorStatus(data, token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StoreVendor);
